.contact {
    background-color: #d3d3d3;
    padding: 60px 20px;
    text-align: left;
    height: 90vh;
}

.contact h2 {
    font-size: 3em;
    margin-bottom: 20px;
    font-family: 'Teachers', sans-serif;
}

.contact p {
    font-size: 1.2em;
    margin-bottom: 10px;
    font-family: 'Teachers', sans-serif;
}

.social-icons a {
    margin: 0 10px;
    font-size: 2em;
    color: #333;
}
