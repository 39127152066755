.about {
    background-color: #1a1a1a;
    color: white;
    box-sizing: border-box;
    height: 100vh; 
}

.text-container {
    height: 100%;
}

.about-text {
    font-size: 1.8em;
    line-height: 1.6;
    font-family: 'Teachers', sans-serif;
    height: 100%; 
    flex-direction: column;
    justify-content: center;
}

.about-text h2 {
    text-align: center;
    font-size: 3em;
    margin-bottom: 40px;
    font-family: 'Teachers', sans-serif;
}

@media (max-width: 768px) {
    .about-text {
        padding: 20px 0;
    }

    .about-text h2 {
        font-size: 2.5em; 

    .about-text {
        font-size: 1em; 
    }
}
}