html {
    scroll-behavior: smooth;
}

.main-page {
    scroll-behavior: smooth;
}

.navbar {
    height: 80px; 
}

.content {
    padding-top: 80px; 
}

.divider {
    border: none;
    height: 1px;
    background-color: #111;
    margin: 0 20px;
}

.nav-link-large {
    font-size: 1.5em; 
    padding: 15px 20px; 
}

.nav-item {
    margin-right: 45px; 
}

@media (max-width: 991.98px) {
    .content {
        padding-top: 56px; 
    }
}
