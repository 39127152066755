.sub-home {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d3d3d3;
    padding: 20px;
    height: 100vh; 
    box-sizing: border-box;
    overflow: hidden;
}

.sub-home-content {
    display: grid;
    grid-template-areas: 
        "left-image right-image"
        "left-image text-content";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    width: 100%;
    height: 100%;
    gap: 20px;
    box-sizing: border-box;
}

.image-left {
    grid-area: left-image;
    align-self: end;
    justify-self: start;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.image-left img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.image-right {
    grid-area: right-image;
    align-self: start;
    justify-self: end;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.image-right img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.text-content {
    grid-area: text-content;
    align-self: start;
    justify-self: start;
    padding: 20px;
    font-size: 1.2em;
    line-height: 1.6;
    font-family: 'Teachers', sans-serif;
    background-color: rgba(211, 211, 211, 0.9);
    width: 100%;
    box-sizing: border-box;
}

@media (min-width: 501px) {
    .sub-home-content {
        grid-template-columns: 1fr 1fr; 
        grid-template-areas: 
            "left-image right-image"
            "left-image text-content";
    }

    .image-left {
        max-width: 40%;
    }

    .image-right {
        max-width: 100%;
    }

    .text-content {
        max-width: 60%;
    }
}

@media (max-width: 1200px) {
    .sub-home-content {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas: 
            "right-image"
            "left-image"
            "text-content";
    }

    .text-content {
        font-size: 1em;
        max-width: 100%;
    }

    .image-left, .image-right {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 768px) {
    .text-content {
        font-size: 0.9em;
    }

    .image-left, .image-right {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .text-content {
        font-size: 0.8em;
    }

    .image-left, .image-right {
        max-width: 100%;
        height: auto;
    }
}
