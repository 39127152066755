.App {
  font-family: 'Teachers', sans-serif;
  margin: 0;
  padding: 0;
}

.main-page {
  scroll-behavior: smooth;
}

.section {
  padding: 60px 20px;
}
