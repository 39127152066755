.visual-media {
    background-color: #b3b3b3;
    padding: 60px 20px;
    text-align: center;
    height: 100vh;
}

.visual-media h2 {
    font-size: 3em;
    margin-bottom: 40px;
    font-family: 'Teachers', sans-serif;
}

.visual-media p {
    font-size: 1.2em;
    font-family: 'Teachers', sans-serif;
}
