.home {
    position: relative;
    background-color: #d3d3d3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    overflow: hidden;
}

.home-content {
    width: 100%;
}

.home-content h1 {
    font-size: 6em; 
    font-weight: bold;
    margin-bottom: 40px;
    font-family: 'Teachers', sans-serif;
}

.center-image {
    max-width: 80%; 
}

.scrolling-text-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none; 
}

.scrolling-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #8c8c8c;
    font-size: 2.5em;
    writing-mode: vertical-rl;
    text-orientation: upright;
    animation: scroll 60s linear infinite;
}

.scrolling-text.left {
    align-self: flex-start;
    animation-name: scrollUp;
}

.scrolling-text.right {
    align-self: flex-end;
    animation-name: scrollDown;
}

.scrolling-text p {
    margin: 0;
    padding: 10px 0;
}

@keyframes scrollUp {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
} 

@keyframes scrollDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(100%);
    }
}
