.projects {
    background-color: #8c8c8c;
    padding: 60px 20px;
    height: 100vh;
}

.projects h2 {
    text-align: left;
    font-size: 3em;
    margin-bottom: 40px;
    font-family: 'Teachers', sans-serif;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project {
    width: 30%;
    margin-bottom: 20px;
    text-align: left;
 
    padding: 10px;
}

.project img {
    width: 100%;
    height: auto;
}

.project h3 {
    margin: 10px 0;
    font-family: 'Teachers', sans-serif;
    color: #d3d3d3;
}

.project p {
    font-size: 1em;
    color: #d3d3d3;
    font-family: 'Teachers', sans-serif;
}

.project a {
    display: block;
    margin-top: 10px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    text-decoration: underline;
}